
import de from 'react-intl/locale-data/de';
import fr from 'react-intl/locale-data/fr';
import it from 'react-intl/locale-data/it';

import PatientIcon from '@material-ui/icons/HowToReg';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';

import messages_de from './translations/messages_de.json';
import messages_fr from './translations/messages_fr.json';
import messages_it from './translations/messages_it.json';

const config = {
  availableLocales: [
    {id: 'de', label: 'Deutsch', messages: messages_de, localeData: de },
    {id: 'fr', label: 'Français', messages: messages_fr, localeData: fr },
    {id: 'it', label: 'Italiano', messages: messages_it, localeData: it }
  ],
  defaultLanguage: 'de',
  servers: {
    signaling: 'https://ms-comm.z4butrwhlxv8.nooon.io:443',
    ice: [
      { urls: "stun:ms-comm.ca7cthywqan.nooon.io:443" },
      { urls: "turns:ms-comm.ca7cthywqan.nooon.io:443?transport=udp", 'username': 'nooon', 'credential': '7eQioJtCw9if' },
      { urls: "turns:ms-comm.ca7cthywqan.nooon.io:443?transport=tcp", 'username': 'nooon', 'credential': '7eQioJtCw9if' }
    ]
  },
  callMetadata: {
    'patient': {
      icon: PatientIcon,
      type: 'boolean',
      required: true,
      title: {
        'de': 'Ist dieser Teilnehmer ein Patient?',
        'it': 'Questo partecipante è un paziente?',
        'fr': 'Ce participant est-il un patient?',
      },
      values: {
        'de': ['Ja', 'Nein', 'Team'],
        'it': ['Sì', 'No', 'Team'],
        'fr': ['Oui', 'Non', 'Team']
      }
    },
    'medication': {
      icon: ScatterPlotIcon,
      type: 'list',
      showIf: {patient: 0},
      required: true,
      title: {
        'de': 'Welches Medikament?',
        'it': 'Quale farmaco?',
        'fr': 'Quel médicament?',
      },
      values: {
        'de': [
          'Aimovig',
          'Aubagio',
          'Avonex',
          'Betaferon',
          'Copaxone',
          'Enbrel',
          'Forsteo',
          'Gammanorm',
          'Gilenya',
          'Hizentra',
          'Humira',
          'Nexavar',
          'Plegridy',
          'Rebif',
          'Tecfidera',
          'Andere'
        ],
        'it': [
          'Aimovig',
          'Aubagio',
          'Avonex',
          'Betaferon',
          'Copaxone',
          'Enbrel',
          'Forsteo',
          'Gammanorm',
          'Gilenya',
          'Hizentra',
          'Humira',
          'Nexavar',
          'Plegridy',
          'Rebif',
          'Tecfidera',
          'Altri'
        ],
        'fr': [
          'Aimovig',
          'Aubagio',
          'Avonex',
          'Betaferon',
          'Copaxone',
          'Enbrel',
          'Forsteo',
          'Gammanorm',
          'Gilenya',
          'Hizentra',
          'Humira',
          'Nexavar',
          'Plegridy',
          'Rebif',
          'Tecfidera',
          'Autres'
        ]
      }
    }
  },
  mailsender: 'MediService Nextcare <noreply@nextcare.io>',
  appName: 'MediService Nextcare'
};

export default config;