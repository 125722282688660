import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import { FormattedMessage, intlShape, defineMessages } from 'react-intl';

import CallMetadataForm from './CallMetadataForm';

const localeMessages = defineMessages({
  'online': {
    id: 'Shared.Chat.Online',
    defaultMessage: 'Online'
  },
  'offline': {
    id: 'Shared.Chat.Offline',
    defaultMessage: 'Offline'
  }
});

class ContactInfo extends Component {
  static contextTypes = {
    intl: intlShape.isRequired,
  }

  render() {
    const agent = this.props.contact.agent;
    const datachannel = this.props.contact.datachannel ? this.props.contact.datachannel.readyState : 'Not existing';

    const result = this.props.contact.pcstats || false;
    let remoteStreams = [], localStreams = [];

    if (this.props.contact.pc) {
      try {
        remoteStreams = this.props.contact.pc.getRemoteStreams();
        localStreams = this.props.contact.pc.getLocalStreams();
      }
      catch (err) {
        console.log(err);
      }
    }

    return (
      <div className="infocontact">
        <List>
          <ListSubheader>
            <FormattedMessage
              id="Supporter.ContactInfo.Partner"
              defaultMessage="Partner"
            />
          </ListSubheader>
          <ListItem
            disabled={true}
          >
            <ListItemText
              primary={this.props.contact.name}
              secondary={this.props.contact.online ? this.context.intl.formatMessage(localeMessages.online) : this.context.intl.formatMessage(localeMessages.offline)}
            />
          </ListItem>
          {agent && <div>
            <ListItem
              disabled={true}
            >
              <ListItemText
                primary={<div><span title={Object.values(agent.browser).join(" ")}>{agent.browser.name}</span> / <span>{Object.values(agent.engine).join(" ")}</span></div>}
                secondary={<span><span title={Object.values(agent.os).join(" ")}>{agent.os.name}</span> / <span>{Object.values(agent.device).length ? Object.values(agent.device).join(" ") : 'Desktop'}</span></span>}
              />
            </ListItem>
          </div>}
          <Divider />
          {this.props.contact.pc &&
            <div>
              <ListSubheader>
                <FormattedMessage
                  id="Supporter.ContactInfo.Connection"
                  defaultMessage="Verbindung"
                />
              </ListSubheader>
              <ListItem
                disabled={true}>
                <div>
                  <p>Datachannel: {datachannel}</p>
                  <p>Signaling state: {this.props.contact.pc.signalingState}</p>
                  <p>ICE Connection state: {this.props.contact.pc.iceConnectionState}</p>
                  {result && <div>
                    <p>
                      <FormattedMessage
                        id="Supporter.ContactInfo.Speed"
                        defaultMessage="Geschwindigkeit"
                      />: {(result.bandwidth.speed/1024).toFixed(1)} KB/s</p>
                    <p>
                      <FormattedMessage
                        id="Supporter.ContactInfo.Partner"
                        defaultMessage="Partner"
                      />:<br/>
                      <FormattedMessage
                        id="Supporter.ContactInfo.ConnectionType"
                        defaultMessage="Verbindung über"
                      />: {result.connectionType.remote.candidateType === 'relayed' ? 'TURN' : 'STUN'} ({result.connectionType.remote.transport.join(', ')})<br/>
                      Video: {result.resolutions.recv.width + 'x' + result.resolutions.recv.height}<br/>
                      Codecs: {result.audio.recv.codecs.concat(result.video.recv.codecs).join(', ')}
                    </p>
                    <p>
                      <FormattedMessage
                        id="Supporter.ContactInfo.Local"
                        defaultMessage="Lokal"
                      />:<br/>
                      <FormattedMessage
                        id="Supporter.ContactInfo.ConnectionType"
                        defaultMessage="Verbindung über"
                      />: {result.connectionType.local.candidateType === 'relayed' ? 'TURN' : 'STUN'} ({result.connectionType.local.transport.join(', ')})<br/>
                      Video: {result.resolutions.send.width + 'x' + result.resolutions.send.height}<br/>
                      Codecs: {result.audio.send.codecs.concat(result.video.send.codecs).join(', ')}
                    </p>
                  </div>}
                  {(localStreams.length || remoteStreams.length) &&
                    <div>
                      <p>Streams:</p>
                      <p>In:</p>{remoteStreams.map(m => <p key={m.id}>{m.id}</p>)}
                      <p>Out:</p>{localStreams.map(m => <p key={m.id}>{m.id}</p>)}
                    </div>
                  }
                </div>
              </ListItem>
            </div>
          }
        </List>
        <CallMetadataForm
          locale={this.props.locale}
          callMetadata={this.props.callMetadata}
          callMetadataValues={this.props.callMetadataValues}
          onChange={(k, v) => this.props.onChangeCallMetadata(k, v)}
        />
      </div>
    )
  }
};

export default ContactInfo;
