import React, { Component } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { FormattedMessage, intlShape } from 'react-intl';

class MetadataInquiry extends Component {
  static contextTypes = {
    intl: intlShape.isRequired,
  }

  state = {
    expanded: true
  };

  handleExpandChange(ex) {
    this.setState({expanded: ex});
  }

  render() {
    const metadataId = this.props.metadataId;
    const metadataField = this.props.metadata[metadataId];
    const hasValue = metadataId in this.props.values;
    const value = hasValue ? this.props.values[metadataId] : null;
    const isMultiple = metadataField.type === 'multiple';

    const radios = metadataField.values[this.context.intl.locale].map((text, ix) => {
      const intlMessageId = "metadata." + metadataId + ".values." + ix;
      return <FormControlLabel key={ix}
        value={ix}
        checked={hasValue && (value + "") === (ix + "")}
        control={<Radio />} 
        label={<FormattedMessage id={intlMessageId} />}
      />;
    });

    const selectionRenderer = (values) => {
      return hasValue ? (isMultiple ? values : [values]).map(ix => this.context.intl.formatMessage({
        id: "metadata." + metadataId + ".values." + ix
      })).join(', ')
      : '';
    };
    
    const largeGridStyles = {display:'grid', gridTemplateRows: '1fr 1fr 1fr 1fr 1fr', gridAutoFlow: 'column'};
    const smallGridStyles = {display:'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr'};
    const valueCount = metadataField.values[this.context.intl.locale].length;

    return <Card ref="card"
      className="inquiry"
      style={{textAlign:'left', display:'inline-block'}}>
      <CardHeader
        className={"metadata-inquiry-header" + (hasValue ? ' filled' : '')}
        title={<FormattedMessage
          id={"metadata." + metadataId + ".title"}
        />}
        action={
          <IconButton aria-label="Expand" onClick={() => this.handleExpandChange(!this.state.expanded)}>
            {this.state.expanded && <ExpandLess />}
            {!this.state.expanded && <ExpandMore />}
          </IconButton>
        }
        style={{alignItems: 'flex-start'}}
        subheader={selectionRenderer(value)}
        avatar={<metadataField.icon />}
        titleTypographyProps={{color: 'inherit', style: {fontSize:'100%'}}}
        subheaderTypographyProps={{color: "inherit"}}
      />
      {this.state.expanded && <CardContent >
        <RadioGroup
          name={metadataId}
          style={valueCount > 5 ? largeGridStyles : smallGridStyles}
          onChange={(e, v) => {
            this.props.onChange(metadataId, v);
          }}>
          {radios}
        </RadioGroup>
      </CardContent>}
    </Card>;
  }
};

export default MetadataInquiry;