import React, { Component } from 'react';

class Video extends Component {
  timeout = null;
  videoTrackCount = 0;

  shouldComponentUpdate(nextProps) {
    if (!this.refs.stream || !this.refs.stream.srcObject) return true;

    if (nextProps.stream && this.refs.stream.srcObject && nextProps.stream.id !== this.refs.stream.srcObject.id) {
      return true;
    }
    
    if (!nextProps.stream) {
      return true;
    }

    if (nextProps.stream.getVideoTracks().length !== this.videoTrackCount) {
      return true;
    }

    if (nextProps.muted !== this.props.muted) {
      if (this.refs.stream) {
        this.refs.stream.setAttribute('muted', nextProps.muted);
      }
      return false;
    }

    return false;
  }

  componentDidMount() {
    this.refs.stream.addEventListener('playing', () => {
      if (this.refs.stream) {
        this.refs.stream.setAttribute('data-orientation', this.refs.stream.videoWidth > this.refs.stream.videoHeight ? 'landscape' : 'portrait');
      }
    });
  }

  componentDidUpdate() {
    let nextProps = this.props;

    if (!this.props.stream) {
      clearTimeout(this.timeout);
      return;
    }

    let setStream = () => {
      this.refs.stream.srcObject = nextProps.stream;

      if (this.props.muted) {
        this.refs.stream.setAttribute('muted', this.props.muted);
      }
      else {
        this.refs.stream.removeAttribute('muted');
      }
      this.refs.stream.setAttribute('playsinline', '1');
      this.refs.stream.setAttribute('autoplay', '1');

      let tracks = nextProps.stream.getVideoTracks();
      this.videoTrackCount = tracks.length;
      let id = tracks.length ? tracks[0].id : 'no tracks';
      console.log(this.props.className, "Reading", id);

      const playPromise = this.refs.stream.play();
      if (playPromise !== undefined) {
        playPromise.catch(error => {
          console.log("ERROR", id, error);
        });
      }

      nextProps.stream.onaddtrack = (e) => { console.log("Stream track added"); };
      console.log("Added stream", nextProps.stream);
    }

    setStream();

    if (this.timeout !== null) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    this.timeout = setTimeout(() => {
      if (this.refs.stream && this.refs.stream.currentTime === 0) {
        // revert to stream before
        console.log("Not playing");
        this.props.onError();
      }
    }, 2000);
  }

  render() {
    return (<video {...this.props} ref="stream" playsInline></video>);
  }
};

export default Video;