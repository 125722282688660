import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './shared/Icons.css';
import config from './config';
import { App } from './App';

import values from 'object.values';
import URLSearchParams from 'url-search-params';

import openSocket from 'socket.io-client';

import { IntlProvider, addLocaleData } from 'react-intl';

let setting = window.localStorage ? window.localStorage.getItem('language') : null;
let language = (navigator.languages && navigator.languages[0]) ||
                     navigator.language ||
                     navigator.userLanguage;
let languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

let localeObj = config.availableLocales.find(l => l.id === setting) ||
    config.availableLocales.find(l => l.id === languageWithoutRegionCode) ||
    config.availableLocales.find(l => l.id === language) ||
    config.availableLocales.find(l => l.id === config.defaultLanguage);

const locale = localeObj.id;
const messages = localeObj.messages;
const lD = localeObj.localeData;

addLocaleData(lD);

if (!Object.values) {
	values.shim();
}

const params = new URLSearchParams(document.location.search);

config.commServer = window.config.nextcare.commServer;
config.iceServers = window.config.nextcare.iceServers;
config.jitsi = window.config;

const socket = openSocket(window.config.nextcare.commServer, {
  rememberTransport: true, 
  'reconnection': true,
  'reconnectionDelay': 500,
  'secure': true,
  'pingTimeout': 20000
});

// add metadata strings to messages
Object.keys(config.callMetadata).forEach(k => {
  messages[['metadata', k, 'title'].join('.')] = config.callMetadata[k].title[locale];

  config.callMetadata[k].values[locale].forEach((c, ix) => {
    messages[['metadata', k, 'values', ix].join('.')] = c;
  });
});

ReactDOM.render(
  <IntlProvider locale={locale} messages={messages}>
    <App
      locale={locale}
      availableLocales={config.availableLocales}
      socket={socket}
      mailsender={config.mailsender}
      iceServers={config.iceServers}
      appName={config.appName}
      jitsiConfig={config.jitsi}
      clientprefix={(user) => {
        let host = window.location.host.split('.');
        
        if (host[0] === 'localhost:2999') {
          host = ['localhost:3000'];
        }
        else {
          host[0] = 'go';
        }

        if (user.email.split('@')[1].split('.')[0].toLowerCase() === 'curarex') {
          host[1] = 'curarex';
        }
        
        host = host.join('.');
        return host;
      }}
      callMetadata={config.callMetadata}
    />
  </IntlProvider>,
document.getElementById('root'));
