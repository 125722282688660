import React, { Component } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { FormattedMessage, intlShape } from 'react-intl';

class CallMetadataForm extends Component {
  static contextTypes = {
    intl: intlShape.isRequired,
  }

  render() {
    const metadata = this.props.callMetadata;
    const elements = Object.keys(metadata).map(k => {
      const metadataField = this.props.callMetadata[k];
      const value = this.props.callMetadataValues[k];
      
      const items = metadataField.values[this.props.locale].map((text, ix) => {
        const intlMessageId = "metadata." + k + ".values." + ix;
        return <MenuItem checked={(value + "") === (ix + "")} value={ix} key={ix}><FormattedMessage
        id={intlMessageId}
      /></MenuItem>
      });

      return <ListItem
        key={k}
        disabled={true}>
        <p style={{marginTop: 0}}>
          <metadataField.icon color="inherit" />
          <FormattedMessage
            id={"metadata." + k + ".title"}
          />
        </p>
        <Select
          value={value || ""}
          multiple={metadataField.type === 'multiple'}
          onChange={(e, ix, v) => this.props.onChange(k, e.target.value)}
        >
          {items}
        </Select>
      </ListItem>;
    });

    return (
      <List className="callmetadata">
        {elements}
      </List>
    )
  }
};

export default CallMetadataForm;
